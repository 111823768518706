import Empty from "@/components/Empty";
import Loader from "@/components/Loader";
import { ActivityTypeEnum, ActivityTypeEnumMaps } from "@/enums";
import activityModel from "@/models/activity.model";
import { useInfiniteScroll, useReactive } from "ahooks";
import dayjs from "dayjs";
import KeepAlive from "keepalive-for-react";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Image, List, PullRefresh, Search, Sticky, Tabs } from "react-vant";
import styles from "./index.module.css";

interface ListViewProps {
  type: string;
  keyword?: string;
  onClick?: (item: any) => void;
}

const ListView = ({ type, keyword, onClick }: ListViewProps) => {
  const { data, loading, loadMoreAsync, reloadAsync, noMore } =
    useInfiniteScroll(
      (d) => {
        return activityModel
          .list({
            params: {
              type: type === "all" ? "" : type,
              keyword,
              current: d ? d?.current + 1 : 1,
              pageSize: 20,
            },
          })
          .then((res) => res?.data);
      },
      {
        reloadDeps: [type, keyword],
        isNoMore: (d) => (d ? !d?.hasMore : true),
      }
    );
  return (
    <PullRefresh onRefresh={reloadAsync}>
      {loading ? (
        <Loader />
      ) : !data || data?.list?.length <= 0 ? (
        <Empty />
      ) : (
        <List
          className={styles.list}
          finished={noMore}
          finishedText=""
          onLoad={async () => {
            await loadMoreAsync();
          }}
        >
          {data?.list?.map((item: any) => (
            <div
              key={item.id}
              className={styles.item}
              onClick={() => onClick && onClick(item)}
            >
              {item.isSoldOut ? (
                <div className={styles.status}>
                  <Image
                    className={styles.icon}
                    src={"/images/activity/status-3.png"}
                  />
                </div>
              ) : dayjs(item.startTime).isAfter(dayjs()) ? (
                <div className={styles.status}>
                  <Image
                    className={styles.icon}
                    src={"/images/activity/status-1.png"}
                  />
                </div>
              ) : (
                <div className={styles.status}>
                  <Image
                    className={styles.icon}
                    src={"/images/activity/status-2.png"}
                  />
                </div>
              )}
              <Image className={styles.image} src={item.image} />
              <div className={styles.title}>{item.title}</div>
              <div className={styles.tags}>
                <div className={styles.limit}>
                  <span className={styles.label}>限量</span>
                  <span className={styles.num}>{item?.limit}份</span>
                </div>
              </div>
              <div className={styles.date}>
                时间:
                <span>
                  {dayjs(item.startTime).format("YYYY-MM-DD HH:mm")}至
                  {dayjs(item.endTime).format("YYYY-MM-DD HH:mm")}
                </span>
              </div>
            </div>
          ))}
        </List>
      )}
    </PullRefresh>
  );
};

export default () => {
  const nav = useNavigate();
  const params = useParams();
  const state = useReactive({
    tab: params?.type ? params?.type.toString() : "all",
    keyword: "",
  });

  const tabs = useMemo(() => {
    return [
      {
        key: "all",
        title: "全部",
        component: ListView,
        cache: true,
      },
      ...Object.keys(ActivityTypeEnumMaps).map((key) => {
        return {
          key,
          title: ActivityTypeEnumMaps[key],
          component: ListView,
          cache: true,
        };
      }),
    ];
  }, []);

  const page = useMemo(() => {
    return tabs.find((tab) => tab.key === state.tab);
  }, [tabs, state.tab]);

  return (
    <div className={styles.page}>
      <Sticky>
        <Search
          className={styles.search}
          placeholder="搜索历史活动"
          clearable
          leftIcon={
            <Image
              className={styles.icon}
              src={require("@/assets/icons/search.png").default}
            />
          }
          value={state.keyword}
          onSearch={(e) => {
            state.keyword = e;
          }}
        />
        <Tabs
          className={styles.tabs}
          type="capsule"
          align="start"
          active={state.tab}
          onChange={(key) => {
            state.tab = key.toString();
          }}
        >
          {tabs.map((item) => (
            <Tabs.TabPane key={item.key} name={item.key} title={item.title} />
          ))}
        </Tabs>
      </Sticky>
      <KeepAlive
        max={20}
        strategy={"PRE"}
        activeName={state.tab + state.keyword}
        cache={page?.cache}
      >
        {page && (
          <page.component
            type={page.key}
            keyword={state.keyword}
            onClick={(item) => {
              if (item.type === ActivityTypeEnum.LOTTERY) {
                nav(`/lottery/detail/${item.id}`);
              } else {
                nav(`/activity/detail/${item.id}`);
              }
            }}
          />
        )}
      </KeepAlive>
    </div>
  );
};
